<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon
        size="15" class="ml-0 mr-3 on-cursor-pointer" v-on="on">
        mdi-help-circle
      </v-icon>
    </template>
    <div class="contract-review-details-approver-tooltip pt-2 pb-2 pr-1">
      <div class="mb-1">
        WBS code = WBS Parent + .Suffix        
      </div>
      <div style="display:flex;font-weight:400">
        <div class="mr-2" style="width: 150px">Entity &amp; Branch</div>
        <div>: WBS Suffix</div>          
      </div>
      <div style="display:flex;">
        <div class="mr-2" style="width: 150px">Shanghai Nielsen</div>
        <div>: B.xxxxxx.01</div>          
      </div>
      <div style="display:flex;">
        <div class="mr-2" style="width: 150px">Guangzhou Nielsen</div>
        <div>: B.xxxxxx.02</div>          
      </div>
      <div style="display:flex;">
        <div class="mr-2" style="width: 150px">GZ Nielsen Shanghai Branch</div>
        <div>: B.xxxxxx.03</div>          
      </div>
      <div style="display:flex;">
        <div class="mr-2" style="width: 150px">GZ Nielsen Beijing Branch</div>
        <div>: B.xxxxxx.04</div>          
      </div>
    </div>
  </v-tooltip>
</template>

<script>
export default {

}
</script>

<style lang="sass">
  .contract-review-details-approver-tooltip
    text-align: left
</style>
